import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$e6bc from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as netlifyRuntime$2876 from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/netlify.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "netlify",
  "domains": [
    "d4wrf43.matjrah.com",
    "images.unsplash.com",
    "source.unsplash.com"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$e6bc, defaults: {} },
  ['netlify']: { provider: netlifyRuntime$2876, defaults: {} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
