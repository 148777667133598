/* eslint-disable  */

export default {
  "signIn": "تسجيل الدخول",
  "tryFree": "جرب مجانًا",
  "home": "الرئيسية",
  "prices": "الأسعار",
  "features": "المميزات",
  "storeApp": "تطبيق المتجر",
  "blog": "المدونة",
  "contactUs": "تواصل معنا",
  "platformName": "منصة متجرة",
  "taxNumber": "الرقم الضريبي",
  "commercialRegistration": "السجل التجاري",
  "sellOnInstagram": "تبيع على انستجرام؟",
  "affiliateProgram": "انضم لنظام العمولة",
  "posSystem": "نظام نقاط البيع POS",
  "faqs": "الأسئلة الشائعة",
  "createFullStore": "إنشاء متجر الكتروني متكامل",
  "termsAndConditions": "الشروط والأحكام",
  "privacyPolicy_": "سياسة الخصوصية",
  "helpCenter": "مركز المساعدة",
  "platformStatus": "حالة المنصة",
  "comparisonWithSallaAndZid": "مقارنة سلة وزد مع متجرة",
  "yourStoreReady": "متجرك صار جاهز في 3 خطوات",
  "matjrahPlatformExperience": "منصة متجرة أسرع وأسهل تجربة لإطلاق متجرك بدون تعقيد",
  "fastAndEasy": "أسرع وأسهل",
  "fastStoreExperience": "تجربة اطلاق المتجر معنا سريعة وبدون تعقيد",
  "designYourStore": "صمم متجرك بنفسك",
  "customizeStore": "تحكم بتفاصيله واجعل منه بصمة مميزة",
  "ourMerchantsAchievedSales": "تجارنا حققوا مبيعات",
  "from": "من",
  "mobileAppForStore": "تطبيق جوال خاص لإدارة المتجر",
  "mobileAppKeepsYouUpdated": "تطبيق جوال خاص لإدارة المتجر يبقيك دائمًا على اطلاع على جديد متجرك بدون الحاجة للدخول إلى لوحة التحكم.",
  "variousMarketingTools": "أدوات مختلفة للتسويق لمتجرك",
  "launchYourStoreNow": "أطلق متجرك الآن وابدأ باستقبال طلباتك",
  "freeTrialPeriod": "* فترة تجريبية مجانية ١٤ يوم",
  "startYourBusinessNow": "ابدأ تجارتك الآن",
  "comprehensivePackages": "باقات متكاملة وشاملة، بدون أي قيود وبدعم كامل لضريبة القيمة المضافة",
  "saveUpTo": "وفر حتى ٣٠٪ مقارنةً بباقي المنصات",
  "monthly": "شهريًا",
  "yearly": "سنويًا",
  "save20": "توفير 20%",
  "bestValue": "الأفضل قيمة",
  "basicPackageFeatures": "كل المميزات التي يحتاجها متجرك مع دعم كامل لضريبة القيمة المضافة",
  "unlimitedProductsOrders": "عدد لا محدود من المنتجات والطلبات",
  "unlimitedCustomers": "عدد لا محدود من العملاء",
  "seoSupport": "دعم تهيئة محركات البحث (SEO)",
  "affiliateCoupons": "نظام التسويق بالعمولة والكوبونات التسويقية",
  "shippingIntegration": "الربط مع شركات الشحن بسهولة",
  "customCSSJS": "تخصيص تصميم المتجر Custom CSS/Js",
  "growthPackageAdditional": "جميع مميزات الباقة الأساسية بالإضافة إلى",
  "storeGrowthManager": "مسئول متابعة نمو المتجر",
  "unlimitedTeam": "فريق عمل غير محدود لإدارة المتجر",
  "advancedReports": "تقارير وإحصائيات متقدمة عن المتجر",
  "customerWallet": "محفظة العميل لإضافة رصيد للعملاء",
  "loyaltyProgram": "نظام الولاء (نقاط المكافآت)",
  "campaignManagement": "إدارة حملات الرسائل عبر اللوحة",
  "name": "الإسم",
  "enterName": "اكتب الاسم",
  "phoneNumber": "رقم الجوال",
  "phonePlaceholder": "555-555-555",
  "email": "البريد الإلكترونى",
  "enterEmail": "ali@example.com",
  "password": "كلمة المرور",
  "enterPassword": "اكتب كلمة المرور",
  "agreeToTerms": "بالتسجيل فأنا أوفق على",
  "registerAccount": "تسجيل الحساب",
  "alreadyHaveAccount": "لديك حساب بالفعل ؟",
  "nameRequired": "الاسم مطلوب",
  "phoneRequired": "رقم الجوال مطلوب",
  "phoneMinLength": "لا يقل عن 8 أرقام",
  "phoneMaxLength": "لا يزيد عن 9 أرقام",
  "emailRequired": "البريد الالكتروني مطلوب",
  "emailValid": "يجب ان يكون البريد الاكتروني صحيح",
  "passwordRequired": "كلمة المرور مطلوبة",
  "passwordMinLength": "لا يقل عن 8 حروف",
  "chooseThemeForStore": "اختر الثيم المناسب لمتجرك",
  "preview": "المعاينة",
  "chooseThemeAndFinish": "اختيار الثيم والإتمام",
  "themeOne": "الثيم الأول",
  "themeTwo": "الثيم الثانى",
  "themeThree": "الثيم الثالث",
  "themeFour": "الثيم الرابع",
  "themeFive": "الثيم الخامس",
  "themeSix": "الثيم السادس",
  "themeSeven": "الثيم السابع",
  "themeEight": "الثيم الثامن",
  "themeNine": "الثيم التاسع",
  "themeTen": "الثيم العاشر",

  "noTechnicalExperience": "لا تحتاج إلى خبرة تقنية",
  "launchStoreIn": "أطلق متجرك في",
  "steps": "خطوات بسيطة",
  "chooseDesign": "اختر التصميم",
  "addProducts": "أضف المنتجات",
  "activateShipping": "فعّل الشحن",
  "currencyLanguageSupport": "دعم اللغات والعملات المختلفة",
  "vatSupport": "دعم ضريبة القيمة المضافة",
  "enableOnlinePayment": "تفعيل الدفع الإلكتروني",
  "acceptPaymentsEasily": "استقبل المدفوعات بسهولة",
  "enableOnlinePaymentContent": "يتيح لك نظام الدفع الإلكتروني استلام المدفوعات من خلال طرق دفع متعددة",
  "businessLicenseRequirement": "يتطلب وجود رخصة تجارية",
  "noMonthlyFee": "بدون رسوم شهرية",

  "supportForGulfBanks": "دعم للبنوك الخليجية",

  "storeServicesOnePlace": "كل خدمات متجرك في مكان واحد",
  "storeServicesOnePlaceContent": "تحصل على كافة الخدمات التي يحتاجها متجرك الإلكتروني من مكان واحد",

  "professionalLogoDesign": "تصميم شعار احترافي",

  "professionalStoreFormatting": "تنسيق احترافي لمتجرك",

  "seoOptimization": "تحسين محركات البحث SEO",
  "seoOptimizationContent": "يساعدك في تحسين ظهور متجرك في محركات البحث",

  "rewardsSystem": "نظام الولاء",
  "rewardsSystemContent": "وفر لعملائك نظام ولاء لتحفيزهم على العودة للشراء",

  "trackAndAnalyzeGrowth": "متابعة وتحليل نمو متجرك",
  "trackAndAnalyzeGrowthContent": "تحليلات وتقارير تساعدك على فهم أداء متجرك",

  "products": "المنتجات",
  "productsDetails": "إدارة المنتجات بكل سهولة وفعالية",

  "customers": "العملاء",
  "customersDetails": "إدارة العملاء وتعزيز تفاعلهم مع متجرك",

  "professionalStoreDesignContent": "يتيح لك تصميم متجرك بشكل احترافي وجذاب",

  "liveChatSupport": "دعم المحادثة المباشرة",
  "liveChatSupportContent": "تواصل مع عملائك مباشرة من خلال المحادثة المباشرة",

  "welcomeSteps": "يا هلا , هذه الخطوات البسيطة ستمكننا من تخصيص متجرك ولوحة التحكم",
  "stepOne": "الخطوة الأولى",
  "finalStep": "الخطوة الأخيرة",
  "storeNameAr": "اسم المتجر باللغة العربية",
  "storeNameArPlaceholder": "مثال: الريحانى",
  "storeNameEn": "اسم المتجر باللغة الانجليزية",
  "storeNameEnPlaceholder": "ex: Ryhany",
  "country": "الدولة",
  "select": "اختر",
  "selectStoreIndustry": "اختر مجال عمل المتجر",
  "next": "التالى",
  "completeRegistration": "إكمال التسجيل",
  "nameEnglishOnly": "يجب أن يكون الاسم باللغة الإنجليزية ولا يحتوي على مسافات أو رموز",

  "signUpFreeTrial": "سجل واحصل على 14 يوم مجانًا",
  "benefitsCreatingStore": "مزايا إنشاء متجرك مع متجرة",
  "joinHundredsOfStores": "انضم لمئات المتاجر",
  "storeFeature1": "جميع المميزات الأساسية لنجاح متجرك",
  "storeFeature2": "فريق المساندة لمساعدتك في إطلاق متجرك",
  "storeFeature3": "أسعار حصرية لشركات الشحن",
  "storeFeature4": "تفعيل الدفع الالكتروني بكل سهولة",
  "storeFeature5": "أدوات تسويقية مختلفة",
  "storeFeature6": "الربط مع منصات التسويق",
  "createNewStoreTitle": "إنشاء متجر الكتروني جديد - منصة متجرة",
  "createNewStoreDescription": "أطلق متجرك الالكتروني في ٣ خطوات وبدون تعقيد مع أسهل تجربة - ابدأ في انشاء متجر الكتروني الآن",
  "createNewStoreKeywords": "انشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني",
  "preparingStore": "جارى تحضير متجركم",
  "preparingStoreData": "جاري إعداد البيانات الخاصة بالمتجر",
  "preparingDesign": "جاري إعداد التصميم والواجهة",
  "redirectingControlPanel": "سيتم تحويلكم للوحة التحكم خلال ثوان",
  "preparingControlPanel": "جاري تهيئة لوحة التحكم ...",
  "matjrahPlatform": "منصة متجرة",
  "continue": "الاستمرار",
  "connectedSuccessfully": "تم الاتصال بنجاح",
  "goToYourStore": "توجه إلى متجرك",
  "noTechnicalExperienceNeeded": "ما تحتاج أي خبرة تقنية",
  "launchYourStoreIn3Steps": "أطلق متجرك في 3 خطوات",
  "chooseStoreDesign": "اختر تصميم متجرك من ثيمات متنوعة",
  "addProductsEasily": "أضف منتجاتك بكل سهولة",
  "activateShippingAndPayment": "فعل خيارات الشحن والدفع المناسبة",
  "storeReady": "متجرك صار جاهز",

  "allYouNeedInOnePlace": "كل ما يحتاجه متجرك في مكان واحد",
  "onlinePayment": "الدفع الالكتروني",
  "shippingAndDelivery": "الشحن والتوصيل",
  "marketingServices": "خدمات التسويق",
  "reportsAndAnalytics": "التقارير والإحصائيات",
  "instantActivationNoFees": "تفعيل فوري دون رسوم شهرية أو تأسيسية",
  "activatePaymentIn48Hours": "فعل الدفع الالكتروني في متجرك خلال 48 ساعة فقط",
  "exclusivePricesMultipleProviders": "أسعار حصرية ومزودين كُثر",
  "serviceProvidersIntegration": "الربط مع مزودي الخدمات من شركات شحن وتوصيل مثل سمسا وارامكس والمزيد. بأسعار حصرية.",
  "clearDiverseReports": "تقارير واضحة ومتنوعة",
  "realTimeReports": "عبر التقارير والتحليلات المحدثة لحظة بلحظة ، راقب اداء متجرك واتخذ القرارات المناسبة لتحسين وتطوير",
  "everythingYourStoreNeeds": "كل ما يحتاجه متجرك في مكان واحد",
  "electronicPayment": "الدفع الالكتروني",
  "shippingAndDelivery": "الشحن والتوصيل",
  "marketingServices": "خدمات التسويق",
  "reportsAndStatistics": "التقارير والإحصائيات",
  "instantActivation": "تفعيل فوري دون رسوم شهرية أو تأسيسية",
  "activateElectronicPayment": "فعل الدفع الالكتروني في متجرك خلال 48 ساعة فقط",
  "exclusiveRatesAndProviders": "أسعار حصرية ومزودين كُثر",
  "connectWithProviders": "الربط مع مزودي الخدمات من شركات شحن وتوصيل مثل سمسا وارامكس والمزيد. بأسعار حصرية.",
  "clearAndDiverseReports": "تقارير واضحة ومتنوعة",
  "monitorStorePerformance": "عبر التقارير والتحليلات المحدثة لحظة بلحظة ، راقب اداء متجرك واتخذ القرارات المناسبة لتحسين وتطوير",



  "traderOpinions": "آراء التجار",
  "slide1Masses": "هذا ثاني متجر وتطبيق سويناه معهم والحمدلله ينافس المواقع المشهورة من حيث التصميم وسرعة التصفح ونطمح للافضل وشكرا لتعاونكم الدائم معنا ودمتم بخير.",
  "slide1FullName": "عبدالله محمد الوصابي",
  "slide1Info": "عبايات اللمسة المميزة",
  "slide2Masses": "فريق الدعم الفني أولًا، فريق رائع بشتى النواحي .. خدمة راقيه و مميزة.",
  "slide2FullName": "احمد علي",
  "slide2Info": "متجر دار حروف",
  "slide3Masses": "نشكر منصة متجرة على الدعم مستمر بالمتجر والتجاوب مع اي استفسار ، رد ودعم سريع ومنصة ممتازة جدا وسهولة للتواصل مع خدمة العملاء كلمة شكر لا تكفي متجرة.",
  "slide3FullName": "محمد خليل",
  "slide3Info": "متجر بيت الأطفال",
  "slide4Masses": "من غير مجامله وشهادة حق وبكل أمانة فريق متجرة متعاونين جداً ومتجاوبين وكنتم أحد اعمدة النجاح بالنسبة لنا ،",
  "slide4FullName": "عبدالله محمد الوصابي",
  "slide4Info": "عبايات اللمسة الجميلة",
  "slide5Masses": "منصة جميلة و سهلة وواجهات بسيطة . و ما شاء الله عليكم اي مشكلة تنحل باسرع وقت . فيكم الخير و البركة٫",
  "slide5FullName": "تركي الزهراني",
  "slide5Info": "متجر أسماك الزينة",
  "slide6Masses": "ممتنه لهم و على خدماتهم شركاء نجاحي .. متعاونين و خلوقين.. تكفيني امانتهن و اسلوبهم الراقي.. و حرصهم على اعلاني بكل جديد يحسن من الموقع.. شكراً لكم",
  "slide6FullName": "وعد",
  "slide6Info": "دودي شوب",
  "slide7Masses": "فخور بإكمال عامي الأول وبكل صدق بالبداية كنت شايم هم الموقع والربط مع الجهات الأخرى لكن هم سوو لي كل شيء ، بكل فخر أوصي بهم ",
  "slide7FullName": "وليد",
  "slide7Info": "متجر ليتل روزا",


  "featuredStores": "متـــــاجر نـــــفخر بهـــــا",
  "mainSlide": "الشريحة الرئيسية",
  "storeTitle1": "متجر راحتي",
  "storeParagraph1": "متجر متخصص ومتميز بالأثاث المنزلي والاكسسوارات أكثر من ١٣ فرع منتشرة حول المملكة.",
  "storeTitle2": "متجر ليتل روزا",
  "storeParagraph2": "متجر متخصص في أزياء الأطفال على الطراز الأسباني.",
  "storeTitle3": "شبرا الطائف",
  "storeParagraph3": "سلسلة متاجر وفروع شبرا الطائف للمواد الغذائية والاستهلاكية في مدينة الطائف.",
  "storeTitle4": "عطار",
  "storeParagraph4": "متجر عطار يقدم أجود انواع الأغذية الطبيعية من انحاء العالم ويقدم دائما بأجود الطبيات من انحاء العالم.",
  "storeTitle5": "ويفر ديزاين",
  "storeParagraph5": "علامة تجارية شبابية تقدم تصميمات ملابس مميزة للجنسين.",
  "storeTitle6": "لارينا بيتيت",
  "storeParagraph6": "متجر متخصص في ملابس الأطفال المميزة.",
  "storeTitle7": "ماجستيا",
  "storeParagraph7": "متجر يقدم مجموعة كبيرة من أجود مستحضرات التجميل.",
  "storeTitle8": "مكتبة مرزا",
  "storeParagraph8": "مكتبة عبدالوهاب مرزا هي سلسلة مكتبات عريقة تأسست عام ١٩٥٧م في المملكة العربية السعودية.",
  "storeTitle9": "دكو ستور",
  "storeParagraph9": "موقع لبيع كل مستلزمات الرياضة.",
  "storeTitle10": "فذ العالمية",
  "storeParagraph10": "موقع لبيع الشماغ والاقلام والجلديات.",
  "storeTitle11": "كنز الصحراء",
  "storeParagraph11": "متجر متخصص بصناعة سبائك الذهب والألماس ويقدم تشكيلات ألماس فاخرة.",
  "storeTitle12": "أسماك الزينة",
  "storeParagraph12": "متجر تربية أسماك الزينة ومستلزماتها.",
  "storeTitle13": "اللمسة الجميلة",
  "storeParagraph13": "متجر متخصص ببيع العبايات النسائية بتصاميم ناعمة ومميزه، تتدمج بين الماضي والحاضر.",
  "storeTitle14": "مستر بي سي",
  "storeParagraph14": "متجر متخصص في الكمبيوتر و الجوال و مستلزماتهم متواجدون في السوق منذ أكثر من 16 عام.",
  "storeTitleKW1": "دار حروف",
  "storeParagraphKW1": "دار نشر كويتية تأسست في عام 2018 ، تهدف لـ رفع مستوى القراءة في الكويت والوطن العربي من خلال طرح العديد من الإصدارات المتنوعة للكتاب.",
  "storeTitleKW2": "دار خطوة",
  "storeParagraphKW2": "دار خطوة للنشر والتوزيع الكتب، دار نشر كويتية تأسست في عام ٢٠١٨، وذلك حرصًا منها على دعم القراءة.",
  "storeTitleKW3": "صندوق اقرأ",
  "storeParagraphKW3": "صندوق اقرا تطبيق وموقع إلكتروني لطلب الكتب الورقية خدمة التوصيل متوفرة لجميع دول العالم متوفر أكثر من ١٠٠٠ كتاب في عدة مجالات متنوعة.",
  "storeTitleKW4": "مكتبة الوردي",
  "storeParagraphKW4": "مكتبة الوردي نقدم لكم ارقى و اجود الادوات المكتبية والقرطاسية وباجمل الاسعار.",
  "storeTitleKW5": "عبايات اللمسة المميزة",
  "storeParagraphKW5": "عبايات اللمسة المميزة تتميز بتصاميم حصرية ومميزة تناسب المرأة السعودية والخليجية موديلات مختلفة تناسب معظم الأذواق وبأسعار مناسبة للجميع.",
  "storeTitleKW6": "اسماك الزينة",
  "storeParagraphKW6": "متجرك الأول في عالم تربية أسماك الزينة.",
  "storeTitleKW7": "ويفرز",
  "storeParagraphKW7": "ويڤر ديزاين علامة تجارية مسجله رسمياً مختصه ببيع الملابس و الاكسسوارات ذات تصميم وطابع مختلف.",
  "storeFeaturesTitle": "خصائص وميزات متجرك على متجرة",
  "productManagement": "إدارة المنتجات والمخزون",
  "productManagementContent": "إدارة شاملة وسهلة لمنتجاتك تمكنك من إضافة عدد لامحدود من المنتجات.",
  "productDiscountControl": "تحكم بتخفيضات المنتجات",
  "bulkDiscounts": "تخفيضات الجملة للمنتجات",
  "currencyLanguageVat": "دعم العملات واللغات وضريبة القيمة المضافة",
  "productOptions": "خيارات متعددة للمنتج",
  "productOptionsDetails": " يمكنك إضافة خيارات متعددة للمنتج مثل الألوان والمقاسات المختلفة وتعيين سعر ومخزون لكل خيار ",
  "customerAdditions": "إضافات العميل على المنتج",
  "customerAdditionsDetails": "تمكنك من السماح للعميل بطلب إضافات يقوم بتعبئتها عند الطلب.",
  "productClassification": "تصنيف المنتجات لأقسام",
  "productClassificationDetails": "تصنيف المنتجات لسهولة التصفح.",
  "brandClassification": "تصنيف المنتجات حسب الماركات",
  "brandClassificationDetails": "تصنيف المنتجات حسب العلامة التجارية.",
  "sales": "المبيعات",
  "salesDetails": "تعرف على مبيعات متجرك لتعزيز النمو.",
  "pageTitle": "مميزات إنشاء متجر - متجرة",
  "pageDescription": "جميع المميزات والخدمات الأساسية اللي تحتاجها لإنشاء متجر الكتروني متكامل.",
  "keywords": "إنشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني",
  "faqsTitle": "الأسئلة الشائعة",
  "viewMoreFaqs": "عرض المزيد من الأسئلة الشائعة",
  "faqNeedTechExperienceTitle": "هل أحتاج إلى خبرة تقنية لإعداد متجري ؟",
  "faqNeedTechExperienceContent": "لا تحتاج لأي خبرة تقنية، في متجرة سهلنا عليك كل شيء وكمان وفرنا لك فريق دعم فني متميز يساعدك في إعداد وتجهيز متجرك ومساعدتك دائما",
  "faqServicesInPackagesTitle": "ماهي الخدمات المشمولة في الباقات؟",
  "faqServicesInPackagesContent": "تشمل الباقات جميع الميزات والخدمات اللازمة لإطلاق متجرك الإلكتروني بداية من مرحلة الإعداد وحتى الربط مع مزودي الخدمات بأسعار حصرية وتفعيل الدفع الالكتروني بدون أي رسوم تأسيسية أو شهرية وحتى التسويق عن طريق نظام التسويق",
  "faqIncludesUpdatesTitle": "هل الاشتراك يشمل تحديثات لمتجري؟",
  "faqIncludesUpdatesContent": "نعم الاشتراك يشمل تحديثات مستمرة بإضافة مميزات جديدة والربط مع مزودي خدمات",
  "faqCancelServiceTitle": "هل أستطيع إلغاء الخدمة والحصول على بيانات متجري ؟",
  "faqCancelServiceContent": "نعم تستطيع في أي وقت أن تفعل ذلك دون أي قيود أو شروط",
  "faqCreateStoreQuicklyTitle": "كيف انشئ متجر الكتروني بسرعة؟",
  "faqCreateStoreQuicklyContent": 'يُمكنك إنشاء متجر إلكتروني على الإنترنت بسهولة مع منصة متجرة أفضل منصة تصميم متاجر إلكترونية في السعودية دون الحاجة لشركة برمجة. <br /> حيث تُمكنك منصة متجرة من إنشاء متجر إلكتروني متكامل؛ لتوفيرها تصميم للمستخدم العادي - التاجر - الذي لا يوجد له معرفة بالبرمجة وتصميم المتاجر.  <br /> كما توفر لك منصة متجر عديد من الميزات والتسهيلات من أجل بدء عملك في التجارة الإلكترونية بسهولة والربح من الإنترنت سريعاً في وقت قصير وبسعر تنافسي.  <br /> لذلك إذا كنت تريد إنشاء متجر إلكتروني متكامل بسهولة عليك الدخول إلى منصة متجرة واختيار الباقة المناسبة لك ومن ثم ستقودك الخطوات بالترتيب لإنشاء متجر إلكتروني في خلال ساعات معدودة.  <br /> ولا تقلق عند إنشاء متجرك بمنصة متجرة، لأنك ستحصل على عدة مزايا تستفاد منها في أي وقت، حيث تتميز المنصة بتوفير دعم متكامل باللغة العربية، إلى الجانب الكثير من الحلول والميزات مثل، الدمج مع أشهر وأكبر شركات الشحن وبوابات الدفع في العالم.  <br /> بالإضافة لإمكانية إنشاء المتجر بكلتا اللغتين "العربية والإنجليزية"، مع وجود دعم فني متكامل على مدار الـ 24 ساعة، وتوفير إمكانية إنشاء تطبيق هاتف جوال لمتجرك أيضًا.',
  "createCompleteStore": "إنشاء متجر الكتروني متكامل",
  "ownCompleteStore": "امتلك متجر إلكتروني متكامل ( موقع + تطبيقات )",
  "completeStoreSetup": "تجهيز وإعداد متكامل المتجر",
  "storeBasicDataSetup": "إعداد البيانات الأساسية للمتجر",
  "vatSetup": "إعداد ضريبة القيمة المضافة",
  "storeContactSetup": "إعداد طرق التواصل الخاصة بالمتجر",
  "policyPagesSetup": "إعداد صفحات السياسات والشروط و الأحكام",
  "socialMediaPagesSetup": ".ضبط وربط صفحات التواصل الإجتماعي الخاصة بالمتحر",
  "shippingAndDeliveryOptions": ".ضبط طرق وخيارات الشحن والتوصيل",
  "supportServices": "الخدمات المساندة",
  "dataEntryHelp": "المساعدة في إدخال البيانات ورفع المنتجات والأصناف.",
  "onlineTrainingSession": "جلسة تدريبية أون لاين تعليمية لإدارة المتجر.",
  "ongoingTechSupport": "دعم فني مستمر للإجابة على أسئتلكم واستفساراتكم.",
  "guidanceForLaunch": "إرشادات ونصائح لإطلاق المتجر بشكل إحترافي.",
  "professionalStoreDesign": "تصميم وتنسيق احترافي للمتجر",
  "storeAppearanceArrangement": "ترتيب وتنسيق شكل المتجر",
  "storeColorSetup": "تهيئة ألوان المتجر بصورة احترافية",
  "uniqueProfessionalDesign": "تصميم إحترافي وفريد ومميز لمتجرك",
  "bannerDesign": "تصميم البنرات وتنسيقها",
  "categoryImageSetup": "تصميم وإعداد صور الأقسام",
  "activateOnlinePayment": "تفعيل الدفع الالكتروني",
  "onlinePaymentActivationNoFees": "تفعيل الدفع الإلكتروني دون أي رسوم تأسيسية أو شهرية.",
  "whyMatjrah": "لماذا متجرة ?",
  "whatsappSupport": "بنتابع معك خطوات تجهيز وإعداد المتجر عن طريق الواتس آب",
  "storeSetupInOnePlace": "تجهيز وإعداد متجرك من مكان واحد",
  "vatAndUpdatesSupport": "دعم ضريبة القيمة المضافة . الحصول على تحديثات مستمرة ومتجددة . تطبيقات إحترافية للمتجر ( آيفون - أندرويد)",
  "unlimitedProductsAndSEO": "عدد طلبات ومنتجات لا محدود. دعم جميع خدمات السيو . ثيمات متعددة ومجانية لشكل المتجر",
  "professionalLaunchSupport": "إرشادات ونصائح لإطلاق المتجر بشكل إحترافي دعم فني مستمر لمساعدتك والرد على أسئلتك",
  "freeTrialAndTraining": "فترة تجريبية لمدة 15 يوم قبل الاشتراك . جلسة تدريبية اون لاين تعليمية لإدارة المتجر",
  "contactUsNow": "تواصل معنا الآن",

  create_online_store_app: "انشاء تطبيق متجر الكتروني (تطبيق اندرويد - تطبيق أيفون)",
  online_store_app_description: "يمكنك الآن انشاء تطبيق متجر الكتروني (تطبيق اندرويد - تطبيق أيفون) لموقعك الإلكتروني بتكلفة مخفضة من خلال موقع متجرة الذي يمنح عملائك تجربة تسوق استثنائية",
  double_your_sales: "ضاعف مبيعاتك ووسع تجارتك",
  enhance_store_identity: "عزز هوية متجرك بتطبيق جوال احترافي",
  stay_close_to_customers: "كن أقرب لعملائك وتواصل معهم بالاشعارات",
  full_control: "التحكم الكامل",
  control_interface_design: "التحكم في شكل الواجهة الرئيسية والواجهات الفرعية",
  identity_customization: "تخصيص الهوية",
  control_identity_colors: "التحكم في الهوية والألوان بشكل كامل",
  style_customization: "تخصيص الستايل",
  control_style_sections: "التحكم في ستايل وطريقة عرض الاقسام",
  notification_control_system: "نظام الاشعارات الفورية",
  send_schedule_notifications: "إرسال إشعارات آنية أو جدولتها للتطبيق وبخصائص متقدمة",
  quick_update: "تحديث سريع",
  quick_publish_updates: "تحديث ونشر تحديثات التطبيق بشكل سريع",
  shopping_cart: "سلة تسوق",
  add_manage_cart_purchase_tracking: "إضافة المشتريات للسلة، إدارة سلة المشتريات، إكمال عملية الشراء والدفع، متابعة حالة الطلب",
  online_payment: "الدفع الاونلاين",
  easy_checkout_payment: "تجربة سهلة لاتمام الطلب والدفع من خلال التطبيق عن طريق الفيزا كارد أو أبل باي",
  easier_product_browsing: "تصفح أسهل للمنتجات",
  customers_browse_products: "من خلال التطبيق يمكن لعملاء متجرك التصفح الأسهل والأسرع للمنتجات بكل سهولة.",
  order_tracking: "متابعة الطلبات",
  track_orders_shipments: "امكانية متابعة الطلبات والشحنات من طرف عملاء المتجر بكل سهولة.",
  interactive_states: "الحالات التفاعلية",
  support_daily_new_states: "دعم الحالات التفاعلية في التطبيق لإضافة حالات جديدة كل يوم",
  popup_windows: "النوافذ المنبثقة",
  show_promo_images_open_app: "تخصيص صور تظهر لعملائك عند فتح التطبيق كالعروض والتخفيضات الجديدة",
  countdown_timer: "عداد العروض التفاعلي",
  engage_customers_countdown: "إجعل ظهرو عروضك على المنتجات يشد عملائك من خلال عداد العروض التفاعلي التنازلي لانتهاء موعد التخفيض.",
  weaver_design: "ويفر ديزاين",
  weaver_design_description: "براند سعودي مختص ببيع الملابس الشبابية مصنف من أفضل 200 تطبيق على مستوى المملكة على منصة أبل، يقدم أحدث التصاميم للملابس وللجنسين.",
  app: "التطبيق",
  native_ios_android: "تطبيق Native iOS & Android",
  support_arabic_english: "دعم اللغتين (عربي / إنجليزي)",
  get_professional_app: "احصل على تطبيق إحترافي لمتجرك الالكتروني",
  subscribe_now: "اشترك الآن",
  subscription_note_ar: "للاشتراك والحصول على تطبيقات لمتجرك ، يلزم الاشتراك بأحد الباقات المدفوعة بمنصة متجرة",
  subscription_note_en: "* باقة موقع تشمل متجر الكتروني مع تطبيقات جوال",
  keywords: "انشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني",
  matjrah: "متجرة",


  app_store_app: "تطبيق جوال خاص بمتجرك على منصتي جوجل وأبل",
  app_store_experience: "امنح عملائك تجربة تسوق استثنائية.",
  more_than_one_and_half: "أكثر من مرة ونصف",
  higher_purchase_probability: "احتمالية شراء عميلك للمنتـــــــج عن طريـــق التطبيــــــق مقــــارنة بالموقـــــع العــادي",
  more_than_75_percent_profit: "أرباح أكثر من 75%",
  purchase_rate_increase: "ارتفاع معدل الشراء عن طريق تطبيقات التسوق بنسبة 75٪ مقارنة بالموقع.",
  four_times_and_more: "أربع مرات وأكثر",
  users_prefer_apps: "يفضل المستخدمين تصفـح المنتجــات عن طريق تطبيقات التسوق.",
  unique_design_for_each_app: "تصميم فريد لكل تطبيق",
  design_app_yourself: "صمم تطبيقك بنفسك من خلال مجموعة من التصاميم الاحترافية والمتنوعة واظهر بهوية مميزة وامنح عملائك انطباعًا احترافيًا عن متجرك.",
  instant_notification_system: "نظام الاشعارات الفورية",
  one_of_the_best_marketing_methods: "من أفضل طرق التسويق لمتجرك ويتفاعل معها العملاء أفضل من الطرق الأخرى.",
  client_interaction_with_notification: "تفاعل العميل مع الإشعار",
  notification_reach_rate: "نسبة وصول الإشعار للعميل",
  native_apps: "تطبيقات Native",
  built_native_apps: "في متجرة نبني لك تطبيقات نيتف لمتجرك تضمن لك السهولة والسرعة وتعطي تجربة استخدام مميزة بلا أعطال أو بطء تقني يؤثر على أداء التطبيق أو يتسبب في تراجع العميل عن اكمال عملية الشراء.",

  mobile_app_title: "إنشاء تطبيق متجر إلكتروني (تطبيق أندرويد - تطبيق آيفون)",
  mobile_app_content: "امنح عملائك تجربة تسوق استثنائية.",
  more_than_one_half: "أكثر من مرة ونصف",
  product_purchase_probability: "احتمالية شراء عميلك للمنتج عن طريق التطبيق مقارنة بالموقع العادي",
  more_than_75_profit: "أرباح أكثر من 75%",
  purchase_rate_increase: "ارتفاع معدل الشراء عن طريق تطبيقات التسوق بنسبة 75٪ مقارنة بالموقع.",
  four_times_or_more: "أربع مرات أو أكثر",
  users_prefer_shopping_apps: "يفضل المستخدمون تصفح المنتجات عن طريق تطبيقات التسوق.",
  unique_design_for_app: "تصميم فريد لكل تطبيق",
  design_your_app: "صمم تطبيقك بنفسك من خلال مجموعة من التصاميم الاحترافية والمتنوعة واظهر بهوية مميزة وامنح عملائك انطباعًا احترافيًا عن متجرك.",
  instant_notifications_system: "نظام الإشعارات الفورية",
  best_marketing_method: "من أفضل طرق التسويق لمتجرك ويتفاعل معها العملاء أفضل من الطرق الأخرى.",
  customer_notification_interaction: "تفاعل العميل مع الإشعار",
  notification_reach_rate: "نسبة وصول الإشعار للعميل",
  native_apps: "تطبيقات نيتف",
  store_native_apps: "في متجرة نبني لك تطبيقات نيتف لمتجرك تضمن لك السهولة والسرعة وتعطي تجربة استخدام مميزة بلا أعطال أو بطء تقني يؤثر على أداء التطبيق أو يتسبب في تراجع العميل عن إكمال عملية الشراء.",
  control_panel_features_title: "خصائص ومزايا لوحة التحكم وصانع التطبيقات",
  control_panel_features_content: "تحكم بتصميم تطبيقك في أي وقت وقم بتعديل عناصر الصفحة الرئيسية وباقي الصفحات و تصاميم صفحات التطبيق دون الحاجة لخطوات إضافية وبشكل فوري من خلال لوحة التحكم وصانع التطبيقات.",
  app_features_title: "خصائص ومزايا التطبيق",
  our_store_apps_title: "تطبيقات متاجرنا",
  app: "التطبيق",
  native_ios_android: "تطبيق نيتف iOS وأندرويد",
  support_arabic_english: "دعم اللغتين (عربي / إنجليزي)",
  get_professional_app: "احصل على تطبيق احترافي لمتجرك الإلكتروني",
  subscribe_now: "اشترك الآن",
  subscription_note_ar: "للاشتراك والحصول على تطبيقات لمتجرك، يلزم الاشتراك بأحد الباقات المدفوعة بمنصة متجرة",
  subscription_note_en: "* باقة الموقع تشمل متجر إلكتروني مع تطبيقات الجوال",
  create_online_store_app: "إنشاء تطبيق متجر إلكتروني",
  online_store_app_description: "يمكنك الآن إنشاء تطبيق متجر إلكتروني (تطبيق أندرويد - تطبيق آيفون) لموقعك الإلكتروني بتكلفة مخفضة من خلال موقع متجرة الذي يمنح عملائك تجربة تسوق استثنائية.",
  double_your_sales: "ضاعف مبيعاتك ووسع تجارتك",
  enhance_store_identity: "عزز هوية متجرك بتطبيق جوال احترافي",
  stay_close_to_customers: "كن أقرب لعملائك وتواصل معهم بالإشعارات",
  full_control: "التحكم الكامل",
  control_interface_design: "التحكم في شكل الواجهة الرئيسية والواجهات الفرعية",
  identity_customization: "تخصيص الهوية",
  control_identity_colors: "التحكم في الهوية والألوان بشكل كامل",
  style_customization: "تخصيص الستايل",
  control_style_sections: "التحكم في ستايل وطريقة عرض الأقسام",
  notification_control_system: "نظام تحكم بالإشعارات",
  send_schedule_notifications: "إرسال إشعارات آنية أو جدولتها للتطبيق وبخصائص متقدمة",
  quick_update: "تحديث سريع",
  quick_publish_updates: "تحديث ونشر تحديثات التطبيق بشكل سريع",
  shopping_cart: "سلة التسوق",
  add_manage_cart_purchase_tracking: "إضافة المشتريات للسلة، إدارة سلة المشتريات، إكمال عملية الشراء والدفع، متابعة حالة الطلب",
  online_payment: "الدفع عبر الإنترنت",
  easy_checkout_payment: "تجربة سهلة لإتمام الطلب والدفع من خلال التطبيق عن طريق بطاقة فيزا أو أبل باي",
  easier_product_browsing: "تصفح أسهل للمنتجات",
  customers_browse_products: "من خلال التطبيق يمكن لعملاء متجرك التصفح الأسهل والأسرع للمنتجات.",
  order_tracking: "تتبع الطلبات",
  track_orders_shipments: "إمكانية متابعة الطلبات والشحنات بسهولة.",
  interactive_states: "الحالات التفاعلية",
  support_daily_new_states: "دعم الحالات التفاعلية وإضافة حالات جديدة كل يوم",
  popup_windows: "النوافذ المنبثقة",
  show_promo_images_open_app: "تخصيص صور تظهر لعملائك عند فتح التطبيق كالعروض والتخفيضات الجديدة",
  countdown_timer: "عداد العروض التنازلي",
  engage_customers_countdown: "اجعل ظهور عروضك يشد عملائك من خلال عداد العروض التنازلي لانتهاء موعد التخفيض.",
  weaver_design: "ويفر ديزاين",
  weaver_design_description: "براند سعودي مختص ببيع الملابس الشبابية، مصنف من أفضل 200 تطبيق على مستوى المملكة على منصة أبل، يقدم أحدث التصاميم للملابس وللجنسين.",
  keywords: "إنشاء متجر إلكتروني، تصميم متجر إلكتروني، تجهيز متجر إلكتروني",
  matjrah: "متجرة",
  professional: "الاحترافي",
  annual_price_professional: "٣٩٩٩ ر.س / سنوياً",

  "attar": "عطار",
  "attar_description": "تطبيق يقدم أجود أنواع الأغذية الطبيعة والبهارات من مختلف أنحاء العالم.",
  "rahaty": "راحتي",
  "rahaty_description": "تطبيق لشركة متجر راحتي للمفارش والأثاث لديه أكثر من 13 فرع منتشرين بالمملكة.",
  "kanz_al_sahra": "كنز الصحراء",
  "kanz_al_sahra_description": "تطبيق لشركة ذهب وجوهرات مختص ببيع الذهب والسبائك وليهم أكثر من فرع بالمملكة وسلطنة عمان.",
  "rosa": "روسا",
  "rosa_description": "تطبيق لمؤسسة مختصة ببيع الورود والزهوروالتنسيقات.",
  "shubra_taif": "شبرا الطائف",
  "shubra_taif_description": "تطبيق لسلسلة أسواق سوبر ماركت لبيع المواد الغذائية والمستلزمات المنزلية لديه 3 فروع.",
  "beauty_10": "بيوتي ١٠",
  "beauty_10_description": "تطبيق مختص ببيع مستحضرات العناية والتجميل لأكثر من 300 براند عالمي.",


  "app": "التطبيق",
  "auto_sync_store_apps": "المزامنة التلقائية بين المتجر والتطبيقات",
  "instant_notification_system": "نظام الإشعارات الفورية",
  "app_control_panel": "لوحة تحكم خاصة بالتطبيق",
  "restricted_permissions": "صلاحيات مقيدة",
  "full_permissions": "صلاحيات كاملة",
  "select_from_designs": "الاختيار بين أكثر من ٢٠ تصميم احترافي",
  "multiple_display_options": "طرق عرض مختلفة ومتنوعة للمنتجات",
  "unlimited_banners": "بنرات ثابتة/متحركة بطرق عرض غير محدودة",
  "app_status_notifications": "اشعارات بتحديثات حالات التطبيقات",
  "abandoned_cart_reminders": "إشعارات بتنبيهات السلات المتروكة",
  "free_technical_support": "الدعم الفني المجاني",
  "order_tracking": "تتبع حالة الطلبات",
  "apple_pay_card_support": "دعم الدفع عن طريق أبل باي - والبطاقات",
  "favorites_feature": "خاصية المفضلة",
  "customer_location_map": "تحديد موقع العميل على الخريطة",
  "font_customization": "تخصيص الخط بالتطبيق",
  "advanced_design_customization": "تخصيص متقدم لتصميم التطبيق",
  "deep_linking": "Deep-Linking - رابط نقل مباشر إلى التطبيق",
  "countdown_timer_feature": "عداد العروض التنازلي",
  "popup_windows_feature": "النوافذ المنبثقة Popups",
  "quick_shortcuts": "الاختصارات السريعة على أيقونة التطبيق",
  "notification_and_offer_bar": "شريط التنبيهات والعروض",
  "interactive_stories": "القصص التفاعلية Stories",
  "integration_analytics_apps": "الربط مع تطبيقات التحليل والإحصائيات Adjust - Firebase - Branch",


  "withYouOnJourney": "معاك في رحلتك",
  "launchTeam": "فريق الانطلاقة",
  "launchTeamDescription": "يساعدك خطوة بخطوة بداية من إعداد متجرك وحتى تفعيل الدفع وطرق الشحن واستقبال أول طلب",
  "supportTeam": "فريق دعم متكامل",
  "supportTeamExtended": "فريق المساندة",
  "supportTeamDescription": "جاهز للرد على استفساراتك وتقديم النصائح والاستشارات لتطوير متجرك ودائما بيساندك حتى تكمل رحلة نجاحك وما تتوقف",
  "manageStoreFromMobile": "إدارة متجرك من الجوال",
  "mobileAppManagementDescription": "تطبيق جوال خاص لإدارة المتجر يبقيك دائمًا على اطلاع على جديد متجرك بدون الحاجة للدخول إلى استخدام الحاسوب.",
  "instantNotifications": "إشعارات فورية عند وصول طلبات جديدة.",
  "easyOrderManagement": "إدارة سهلة للطلبات والمنتجات.",
  "professionalMobileAppsForStore": "تطبيقات جوال احترافية لمتجرك",
  "buildYourOwnAppDescription": "يمكنك بناء تطبيق جوال احترافي لمتجرك بنفسك خلال 48 ساعة والتحكم الفوري في خيارات التصميم في أي وقت.",
  "nativeApp": "تطبيق محلي",
  "learnMoreAboutAppFeatures": "اعرف أكثر عن ميزات التطبيق",
  "allServicesInOnePlace": "خدماتك في مكان واحد",
  "servicesMarketDescription": "من خلال سوق الخدمات بلوحة التحكم يمكنك طلب خدمات مساندة في أي وقت مثل:",
  "logoDesign": "تصميم شعار احترافي للمتجر",
  "bannerDesigns": "تصاميم بنرات تسويقية",
  "productDataEntry": "إدخال بيانات المنتجات أو نقلها",
  "marketingServicesIntegration": "الربط مع خدمات التسويق",
  "viewPackageDetails": "عرض تفاصيل الباقات",


  "createProfessionalStore": "انشاء متجر الكتروني احترافي في 3 خطوات لبيع منتجاتك بنجاح",
  "createProfessionalStoreDescription": "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في تجهيز متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح على الإنترنت دون الحاجة لتعلم البرمجة أو كتابة الأكواد",
  "review1": "هذا ثاني متجر وتطبيق سويناه معهم والحمدلله ينافس المواقع المشهورة من حيث التصميم وسرعة التصفح ونطمح للافضل وشكرا لتعاونكم الدائم معنا ودمتم بخير.",
  "review2": "فريق الدعم الفني أولًا، فريق رائع بشتى النواحي .. خدمة راقيه و مميزة.",
  "review3": "نشكر منصة متجرة على الدعم مستمر بالمتجر والتجاوب مع اي استفسار ، رد ودعم سريع ومنصة ممتازة جدا وسهولة للتواصل مع خدمة العملاء كلمة شكر لا تكفي متجرة.",
  "review4": "من غير مجامله وشهادة حق وبكل أمانة فريق متجرة متعاونين جداً ومتجاوبين وكنتم أحد اعمدة النجاح بالنسبة لنا ،",
  "review5": "منصة جميلة و سهلة وواجهات بسيطة . و ما شاء الله عليكم اي مشكلة تنحل باسرع وقت . فيكم الخير و البركة٫",
  "storeName1": "عبايات اللمسة المميزة",
  "storeName2": "متجر دار حروف",
  "storeName3": "متجر بيت الأطفال",
  "storeName4": "عبايات اللمسة الجميلة",
  "storeName5": "متجر أسماك الزينة",
  "jsonldDescription": "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في الحصول على متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح في متجر على الإنترنت دون الحاجة لتعلم البرمجة  أو كتابة الأكواد",
  "organizationDescription": "انشاء متجر الكتروني احترافي اصبح سهل مع منصة متجرة التي تساعدك في الحصول على متجر إلكتروني فعال في 3 خطوات حتى تبيع منتجاتك بنجاح في متجر على الإنترنت دون الحاجة لتعلم البرمجة  أو كتابة الأكواد",
  "keywords": "انشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني",


  "affiliateProgramTitle1": "برنامج",
  "affiliateProgramTitle2": "التسويق بالعمولة",
  "affiliateProgramTitle3": "من منصة متجرة",
  "affiliateFeaturesTitle": "ميزات الاشتراك في برنامج التسويق بالعمولة",
  "featureFreeJoin": "الانضمام للبرنامج مجاني لأي شخص وبدون أي شروط",
  "featureSupport": "دعم فني مباشر للرد على استفساراتك حول المنصة",
  "featureReports": "يمكنك معرفة تقرير عن المتاجر المشتركة من خلالك و في أي باقة",
  "featureMarketingMaterials": "ستحصل على جميع المعلومات والملفات والتصاميم التي تساعدك للتسويق للمنصة",
  "earn15PercentCommission": "احصل على %15 عمولة على كل اشتراك",
  "earnCommissionForEachSubscription": "مقابل كل تاجر يشترك في أحد الباقات عن طريقك ستحصل على عمولة قدرها %15 من قيمة كل اشتراك",
  "earnIf5StoresJoin": "إذا اشترك 5 متاجر في باقة",
  affiliateProgram: "برنامج التسويق بالعمولة",
  affiliatePlatform: "التسويق بالعمولة من منصة متجرة",
  increaseYourIncome: "زد دخلك الآن مع برنامج التسويق بالعمولة الخاص بمنصة متجرة.",
  joinNow: "انضم الآن",
  featuresOfAffiliateProgram: "ميزات الاشتراك في برنامج التسويق بالعمولة",
  freeJoin: "الانضمام للبرنامج مجاني لأي شخص وبدون أي شروط",
  directSupport: "دعم فني مباشر للرد على استفساراتك حول المنصة",
  reportAccess: "يمكنك معرفة تقرير عن المتاجر المشتركة من خلالك و في أي باقة",
  marketingMaterials: "ستحصل على جميع المعلومات والملفات والتصاميم التي تساعدك للتسويق للمنصة",
  earn15Percent: "احصل على %15 عمولة على كل اشتراك",
  earnPerSubscription: "مقابل كل تاجر يشترك في أحد الباقات عن طريقك ستحصل على عمولة قدرها %15 من قيمة كل اشتراك",
  if5StoresJoin: "إذا اشترك 5 متاجر في باقة",
  advancedAnnual: "المتقدمة السنوية",
  youWillEarn: "ستحصل على",
  currency: "ريال",
  basicAnnual: "الأساسية السنوية",
  advancedMonthly: "المتقدمة الشهرية",
  basicMonthly: "الأساسية الشهرية",
  whoCanJoin: "من يمكنه الانضمام إلينا؟",
  companies: "الشركات",
  companiesDescription: "هل أنت شركة ولديك عملاء مهتمون بالتجارة الإلكترونية؟ كن شريكًا معنا الآن وزد أرباحك دون مجهود اضافي مع كل اشتراك يتم عن طريق شركتك.",
  vloggersYouTubers: "الفلوجرز واليوتيوبرز",
  vloggersYouTubersDescription: "هل أنت يوتيوبر تقدم محتوى مرئي هادف لمتابعيك؟ قدم لهم مراجعة حول منصات التجارة الإلكترونية وبماذا تتميز منصة وشجعهم على الاشتراك.",
  influencers: "المؤثرون",
  influencersDescription: "هل لديك قاعدة متابعين يثقون في رأيك؟ شجعهم لبدء رحلتهم في التجارة الإلكترونية وقم بعرض منصة متجرة عليهم و اشرح لهم أي الباقات تتناسب مع طبيعة وحجم عملهم.",
  bloggers: "المدوّنون",
  bloggersDescription: "هل تمتلك مدونة قناة متخصصة بالتقنية / التسويق / التجارة الإلكترونية؟ قم بعمل مراجعة عن المنصة في مدونتك وقم بإضافة رابط الإحالة الخاص بك واحصل على عمولتك.",
  howSystemWorks: "كيف يعمل النظام؟",
  contactUsToJoin: "تواصل معنا للانضمام",
  getReferralLink: "احصل على رابط الإحالة",
  startMarketing: "ابدأ بالتسويق واحصل على عمولتك",
  affiliateTrackingNote: "بمجرد تسجيل التاجر من خلال رابطك، ومن ثم الإشتراك والدفع راح تحسب لك عمولتك ، رابط الإحالة يظل فعال حتى 60 يوم على جهاز التاجر لضمان تتبع فعال لرابط الإحالة الخاص بك.",
  startEarningNow: "ابدأ الربح الآن",
  getAffiliateLink: "احصل على رابط الأفلييت الخاص بك وابدأ في تحقيق الأرباح.",
  joinCommissionProgram: "انضم لنظام العمولة",
  affiliateProgramDescription:
    "برنامج التسويق بالعمولة من منصة متجرة زد دخلك الآن مع برنامج التسويق بالعمولة الخاص بمنصة متجرة لتصميم وانشاء متجرك الالكتروني",
  affiliateProgramKeywords:
    "انشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني",
  siteName: "متجرة",

  "contactUsTitle": "تواصل معنا",
  "contactUsDescription": "منصة متجرة لمواقع و تطبيقات المتاجر الإلكترونية هي منصة سعودية مسجلة بوزارة التجارة و الإستثمار السعودية ( مؤسسة المستشار لتقنية المعلومات ) تحت سجل التجاري رقم: 4030372176",
  "contactNumberLabel": "رقمنا الموحد",
  "businessHours": "مواعيد العمل الرسمية من الأحد للخميس 9 ص - 5 م",
  "contactFormPrompt": "أو تعبئة نموذج التواصل بالأسفل وسنقوم الرد في أقرب وقت.",
  "nameLabel": "اسمك الكريم",
  "namePlaceholder": "الاسم",
  "emailLabel": "البريد الالكتروني",
  "emailPlaceholder": "ali@example.com",
  "messageLabel": "الرسالة",
  "messagePlaceholder": "برجاء كتابة الرسالة والتفاصيل هنا",
  "sendButton": "أرسل",
  "errorMessage": "هناك مشكلة في إرسال رسالتك",
  "successMessage": "شكرا لتواصلك سوف نتصل بك قريبا!",
  "closeButton": "إغلاق",
  "nameRequired": "الاسم مطلوب",
  "emailRequired": "البريد الالكتروني مطلوب",
  "emailInvalid": "يجب ان يكون البريد الاكتروني صحيح",
  "messageRequired": "الرسالة مطلوب",

  privacyPolicy: {
    title: "سياسة الخصوصية والاستخدام",
    description:
      "نتوجه بالشكر الجزيل للسادة مستخدمي وتجار منصة متجرة على ثقتكم بالتعامل والاشتراك في منصتنا , كما نؤكد لكم أننا نسعى ونحرص بشدة للحفاظ على المعلومات الخاصة بالتجار و على سلامة وسرية بيانات كافة مستخدمي المنصة من تجار وعملاء ، ونتخذ كافة التدابير الوقائية لحماية تلك البيانات.",
    section1Title: "سياسة الخصوصية الخاصة بمنصة متجرة",
    section1Text1:
      "نتوجه بالشكر الجزيل للسادة مستخدمي وتجار منصة متجرة على ثقتكم بالتعامل والاشتراك في منصتنا , كما نؤكد لكم أننا نسعى ونحرص بشدة للحفاظ على المعلومات الخاصة بالتجار و على سلامة وسرية بيانات كافة مستخدمي المنصة من تجار وعملاء ، ونتخذ كافة التدابير الوقائية لحماية تلك البيانات.",
    section1Text2:
      "إن هذه الوثيقة تحيطكم علماً بسياسة الخصوصية وسرية المعلومات المعمول بها في منصة متجرة لتوضيح وتحديد آلية السرية والخصوصية المعمول بها في منصة متجرة لذى يرجى الإطلاع عليها علماً أنه بمجرد إشتراككم بمنصة متجرة جميع معلوماتكم تخضع لهذه السياسة.",
    section2Title: "سياسة الخصوصية الخاصة بمنصة متجرة تشمل البنود التالية :",
    section2Text1:
      "المعلومات التي تحصل عليها منصة متجرة وتحتفظ بها في أنظمتها الإلكترونية:",
    section2Text2:
      "المعلومات الشخصية الخاصة بالتاجر، كالاسم والبريد الإلكتروني، ورقم الهوية الوطنية أو رقم الإقامة",
    section2Items: [
      "يجب على التاجر أن يوافق على تزويدنا بكافة البيانات الشخصية التي نحتاجها منه , مع موافقته أيضاً على تزويدنا ببعض البيانات الخاصة بعملائه التي نطلبها منه .",
      "تطلب منصة متجرة بعض البيانات الأخرى لكي يتمكن التاجر من تفعيل الدفع الالكتروني لمتجره الإلكتروني كرقم سجله التجاري , وصورة من سجله التجاري , وصورة من هويته الشخصية ورقم الايبان للحساب البنكي الخاص بالمتجر .",
      "نقوم بجمع بعض البيانات من التاجر مثل الجهاز والمتصفح المستخدم للدخول للمنصة وقت دخول التاجر للمنصة وكذلك عنوان البروتوكول الخاص بالتاجر .",
      "كقاعدة عامة فإن جميع هذه المعلومات لا يطلع عليها إلا بعض القائمين على منصة متجرة وذلك بعد حصولهم على تصريح للإطلاع عليها من قِبل الإدارة – عادة ما يكون التصريح محدد ومقيّد وتخضع لرقابة قانونية وإدارية من قِبل منصة متجرة – ولن يتم نشر أو بث هذه المعلومات للغير.",
    ],
    section3Title: "سرية المعلومات الخاصة بالمتاجر :",
    section3Items: [
      "تسعى منصة متجرة على الحفاظ على البيانات الشخصية الخاصة بالسادة مستخدمي منصة متجرة , ونتخذ كافة الإجراءات لحماية بياناتكم الشخصية بمنصتنا .",
      "نعمل بكافة الوسائل للحفاظ على بيانات كافة مستخدمي المنصة بحيث تصبح بأمان بمنصتنا , ونسعى للحفاظ على خصوصية بيانات السادة التجار بكل الطرق الممكنة.",
      "تعمل منصة متجرة على حماية معلومات التُجّار والمتاجر بموجب أنظمة حماية إلكترونية وتقنية ذات جودة عالية ويتم تحديثها بشكل مستمر ودوري.",
      "في حال تعرّض أحد التُجّار أو تعرضت إحدى المتاجر إلى إختراق تقني من نوعٍ ما فإن منصة متجرة تلتزم بإبلاغ التاجر أو المتجر حيال هذا الإختراق على وجه السرعة.",
    ],
    section4Title: "استخداماتنا للبيانات الخاصة بمستخدمي منصتنا:",
    section4Items: [
      "يتم استخدام بيانات مستخدمي المنصة في حالات محددة وللموظفين المسؤولين عن القيام بذلك .",
      "تعمل منصة متجرة بكافة الطرق للحفاظ على سلامة المنصة وسلامة المستخدمين المشتركين فيها , وإذا حدثت أي مخالفة من قبل أي مستخدم أو مشترك , فهذا سيجعله يقع تحت بنود وأحكام شروط المستخدمين , وتطبق عليه العقوبات المذكورة.",
    ],
    section5Title:
      "استخدامنا البيانات الشخصية التي نطلبها من عملاء التاجر المشترك بمنصة متجرة :",
    section5Items: [
      "نحتاج بعض البيانات الشخصية من عملاء التاجر , وهذه البيانات تكون مثل الإيميلات الخاصة بالعملاء واسمائهم وارقام هواتفهم .",
      "نجمع بيانات عملاء التاجر من أجل العمل على تحسين الخدمات المقدمة منا ومعرفة احتياجات عملاء التاجر والعمل على تنفيذها .",
    ],
    section6Title: "التعامل مع الأطراف الثالثة",
    section6Text:
      "من الممكن أن يتم تزويد الأطراف الثالثة ببعض البيانات الخاصة بالتجار الموجودين بالمنصة أو عملائهم على سبيل المثال لا الحصر : اسم المستخدم – الهاتف الشخصي – البريد الإلكتروني. , ويجب أن يعرف جميع مستخدمي المنصة ذلك جيداً , وأن يوافقوا عليه , فنحن نزود الأطراف الثالثة بتلك البيانات وذلك حتى يتمكّن مقدم الخدمة ( الطرف الثالث) من تقديم الخدمة المطلوبة والتي اشترك بها المستخدم.",
  },

  instagram: {
    metaTitle: "صمم متجر انستجرام ووفر أسرع وأسهل تجربة شراء لكل العملاء",
    metaDescription: "اربط متجر انستجرام الان بمتجرك الإكتروني ...",
    metaKeywords: "انشاء متجر الكتروني, تصميم متجر الكتروني ...",
    sellOnInstagram: "تبيع على انستجرام؟",
    organizeYourBusiness: "نظم تجارتك الآن",
    ownAnOnlineStore: "وامتلك متجر الكتروني",
    tryFree: "جرب مجانًا",
    doubleYourSales: "ضاعف طلباتك ومبيعاتك",
    expandReach: "ضاعف مبيعاتك و وسع تجارتك وزود انتشار متجرك",
    receiveOrdersProfessionally: "استقبل طلباتك باحترافية",
    receiveOrdersClearly: "استقبل طلبات أكثر عن طريق خيارات واضحة ومحددة.",
    getPaidFaster: "حصل مدفوعاتك أسرع",
    receivePaymentsQuickly: "استلم مدفوعاتك أول بأول بدلًا من الدفع الكاش.",
    easyFastShopping: "امنح عملائك تجربة شراء أسهل وأسرع",
    storeMoreOrganized: "متجرك منظم أكثر",
    activateProductTags: "فعل خاصية تاقات المنتجات لصفحتك على انستجرام",
    makeBuyingEasier: "سهل على متابعينك عملية الشراء ...",
    tagAppears: "1. يظهر التاق",
    productDetailsShow: "2. تظهر تفاصيل المنتج",
    customerOrdersProduct: "3. يطلب عميلك المنتج",
    sliderTitle1: "اعرض منتجاتك باحترافية",
    sliderDetails1: "اظهر باحترافية أمام عملائك ...",
    sliderTitle2: "كن أقرب لعملائك",
    sliderDetails2: "من خلال لوحة التحكم يمكنك التعرف ...",
    sliderTitle3: "راقب نمو متجرك",
    sliderDetails3: "تابع تقارير متجرك واعرف منتجاتك الأكثر طلبًا ...",
    sliderTitle4: "اكسب ثقة عملائك",
    sliderDetails4: "بالمقارنة مع متجر على انستجرام يثق العملاء ...",
  },

  comparison_platform: "مقارنة منصة",
  comparison_other_platforms: "مع المنصات الأخرى",
  mobile_app_packages: "باقات تطبيقات الجوال ( آيفون - أندرويد)",
  platform_zid: "زد",
  platform_salla: "سلة",
  additional_fee: "برسوم إضافية",
  yearly: "سنويًا",
  monthly: "شهريًا",
  save_over_35_percent: "وفر أكثر من 35%",
  save_up_to: "وفر حتى",
  save_up_to_40_percent: "وفر حتى 40%",
  save_more_than: "وفر أكثر من",
  compare_with_other_platforms: "باشتراك التطبيقات مقارنةً بالمنصات الأخرى",
  pricing_comparison: "مقارنة الأسعار",
  website_packages: "باقات موقع المتجر الإلكتروني",
  basic_plan: "الباقة الأساسية",
  basic_plan_features: "(موقع فقط + ميزات محدودة)",
  basic_plan_professional_features: "(موقع فقط ، ميزات إحترافية)",
  growth_plan: "باقة النمو",
  growth_plan_features: "(موقع فقط + ميزات أكثر)",
  advanced_plan: "الباقة المتقدمة",
  advanced_plan_features: "(موقع فقط + ميزات محدودة)",
  advanced_plan_professional_features: "(موقع فقط ، ميزات إحترافية متقدمة)",
  pro_plan: "الباقة الاحترافية",
  pro_plan_features: "(موقع فقط + ميزات أكثر)",
  cur: "ر.س",
  currency: "ريال سعودي",
  "260": "٢٦٠",
  "2600": "٢٦٠٠",
  "460": "٤٦٠",
  "4600": "٤٦٠٠",
  "13800": "١٣٨٠٠",
  "99": "٩٩",
  "990": "٩٩٠",
  "299": "٢٩٩",
  "2990": "٢٩٩٠",
  "180": "١٨٠",
  "1800": "١٨٠٠",
  "250": "٢٥٠",
  "2500": "٢٥٠٠",
  "5500": "٥٥٠٠",
  "4995": "٤٩٩٥",
  "3500": "٣٥٠٠",
  "2000": "٢٠٠٠",
  "1200": "١٢٠٠",


  "ComparisonSaudiEcommercePlatforms": "مقارنة منصات التجارة الإلكترونية في السعودية",
  "description": "تعرف على أفضل منصة لبناء المتاجر الإلكترونية وأهم المزايا التي يجب أن تتضمنها من خلال مقارنة بين منصات التجارة الالكترونية السعودية متجرة وسلة وزد",
  "basic_features": "المميزات الأساسية",
  "unlimited_products": "عدد المنتجات",
  "unlimited": "غير محدود",
  "language_support": "دعم اللغتين ( العربية - الانجليزية )",
  "supports_both_languages": "يدعم اللغتين",
  "currency_support": "دعم العملات المختلفة",
  "available_all_plans": "متوفر في جميع الباقات",
  "paid_plans_only": "المدفوعة فقط",
  "vat_support": "دعم ضريبة القيمة المضافة",
  "available_growth_plan_and_above": "متوفر في باقة النمو فما أعلى",
  "store_management_app": "تطبيق إدارة المتجر iOS و Android",
  "seo_support": "تحسين محركات البحث SEO",
  "not_available": "غير متوفر",
  "accounting_integration": "الربط مع الخدمات المحاسبية",
  "management_reports": "تقارير وإحصائيات للإدارة",
  "basic_reports_first_plan_advanced_reports_second_plan": "تقارير أساسية الباقة الأولى <br/> تقارير متقدمة في الباقة الثانية",
  "basic_reports_all_plans_advanced_reports_highest_plan": "تقارير أساسية بكل الباقات <br/> تقارير متقدمة بأعلى باقة فقط",
  "technical_support": "الدعم الفني",
  "available_direct_contact": "متوفر - دعم بتواصل مباشر",
  "custom_domain_integration": "الربط مع نطاق خاص",
  "free_with_annual_plans": "مجانًا مع الباقات السنوية",
  "available_additional_fees": "متوفر برسوم إضافية",
  "store_setup_service": "تجهيز المتجر من خلال المنصة",
  "store_design": "تصميم المتجر",
  "basic_design_option_additional_design_service": "تصميم أساسي مع خيار إضافة خدمة تصميم وتجهيز",
  "basic_design_with_paid_templates": "تصميم أساسي مع قوالب مدفوعة",
  "admin_team": "موظفين الإدارة ( فريق العمل )",
  "two_users_first_plan_unlimited_second_plan": "متوفر عدد 2 الباقة الأولى وبعدد غير محدود الباقة الثانية",
  "maximum_ten_highest_plan": "بحد أقصى 10 في أعلى باقة",
  "maximum_twenty_highest_plan": "بحد أقصى 20 في أعلى باقة",
  "data_transfer": "نقل بيانات التاجر من منصة ثانية",
  "free_all_plans": "متوفر في جميع الباقات مجاناً",
  "available_highest_plan": "متوفر في أعلى باقة فقط",
  "css_design_control": "التحكم بتصميم المتجر CSS",
  "top_two_plans_only": "أعلى باقتين فقط",
  "growth_tracking": "متابعة نمو المتجر",
  "available_advanced_plan": "في الباقة الثانية المتقدمة",
  "shipping_and_payment": "الشحن والدفع",
  "online_payment_integration": "الربط مع الدفع الإلكتروني",
  "shipping_company_integration": "الربط مع شركات الشحن المتنوعة",
  "payment_gateway_integration": "الربط مع بوابات دفع مختلفة",
  "tabby_tamara_integration": "الربط مع تابي - تمارا",
  "marketing": "التسويق",
  "ads_integration": "الربط مع الخدمات الإعلانية <br /> Ads Pixel: Snapchat, Twitter, Facebook, Google Ads, Google Tag Manager",
  "abandoned_cart": "السلات المتروكة",
  "seo_tools": "أدوات تحسين الظهور في محركات البحث SEO",
  "loyalty_system": "نظام الولاء",
  "available_no_additional_fee_second_plan": "متوفر بدون رسوم إضافية ضمن الباقة الثانية",
  "additional_fee_paid_plans_only_299_month_2990_year": "متوفر برسوم إضافية - الباقات المدفوعة فقط 299 ريال / شهر - 2990 ريال /سنة",
  "mobile_apps": "تطبيقات جوال ( آيفون - أندرويد ) للمتجر",
  "mobile_apps_integration": "تطبيقات الجوال",
  "via_platform": "عن طريق المنصة",
  "external_service_provider": "مزود خدمة خارجي",
  "mobile_app_subscription": "أسعار اشتراك التطبيقات الجوال",
  "basic_app_3500_year_professional_app_advanced_unique_4999_year": "التطبيق الاساسي <br/> 3500 ر.س / سنوياً<br/>التطبيق الإحترافي بميزات متقدمة وفريدة من نوعها<br/> 4999 ر.س / سنوياً",
  "5500": "5500",
  "4995": "4995",
  "cur": "ر.س",
  "yearly": "سنويًا",
  "ComparisonSaudiEcommercePlatforms": "مقارنة منصات التجارة الإلكترونية في السعودية",
  "platform_matjrah": "منصة متجرة",
  "comparison_matjrah_other_platforms": "مقارنة منصة متجرة مع المنصات الأخرى",
  "pricing_packages_description": "باقات أسعار مختلفة وتكلفة إنشاء متجرإلكتروني تناسب الجميع مع توفير جميع الخدمات الأساسية اللازمة لإنشاء متجرك والأدوات التي تساعد متجرك في النمو",
  "highest_plan_only": "أعلى باقة فقط",

  "pos_page": {
    "top_title": "احصل على متجر الكتروني متكامل مع نظام POS",
    "bottom_title": "استقبل طلباتك بكل سهولة من أي مكان مع مزامنة فورية للمخزون بين المتجر الالكتروني ونظام نقاط البيع",
    "single_inventory": "مخزون واحد .. لوحة تحكم واحدة",
    "single_system": "لاتحتاج الاشتراك مع اكثر من نظام .. نظام واحد يجمع لك كل الحلول",
    "supports_multiple_devices": "يدعم عدة أجهزة",
    "get_store_with_pos": "احصل على متجر الكتروني مع نظام POS",
    "month_price": "ريال/شهر",
    "year_price": "ريال/سنة",
    "subscribe_now": "اشترك الآن",
    "subscription_note": "* يتطلب الاشتراك في أحد باقات المنصة لتفعيل النظام",
    "compliant_with_requirements": "متوافق مع متطلبات هيئة الزكاة والدخل",
    "get_complete_store_with_pos": "احصل على متجر الكتروني متكامل مع نظام نقاط البيع الآن",
    "meta_title": "انشاء متجر الكتروني مع نظام pos system",
    "meta_description": "انشاء متجر الكتروني مع نظام pos system أمر سهل مع متجرة التي تقوم بتصميم وتجهيز متجر الكتروني متكامل مع نظام POS بلوحة تحكم واحدة تعمل مع كل الأجهزة، احصل على عرض سعر الآن",
    "meta_keywords": "انشاء متجر الكتروني, تصميم متجر الكتروني, تجهيز متجر الكتروني",
    "site_name": "متجرة",
    "publisher": "منصة متجرة",
    "thermal_printers": "الطابعات الحرارية",
    "barcode_scanner": "قارئ الباركود",
    "tablet_devices": "الأجهزة اللوحية",
    "works_offline": "العمل بدون انترنت"
  },


  'welcome': 'مرحبا',
  'phoneNumber': '966555252984',
  'basicPackage': 'الباقة الأساسية',
  'growthPackage': 'الباقة المتقدمة',
  'currency': 'ريال',
  'cur': 'ر.س',
  'basicPackagePrice': '1800',
  'growthPackagePrice': '2500',
  'growthPackagePriceMonthly': '250',
  'CreateOnlineStore': 'إنشاء متجر الكتروني احترافي بنقرة زر واحدة - منصة متجرة',
  'ComparisonSaudiEcommercePlatforms': 'مقارنة بين منصات التجارة الالكترونية السعودية (متجرة، سلة، زد)',
  '260': '250',
  '2600': '2,600',
  '460': '460',
  '4600': '4,600',
  '13800': '13,800',
  '99': '99',
  '990': '990',
  '299': '299',
  '2990': '2,990',
  '180': '180',
  '1800': '1,800',
  '1000': '1,000',
  '5500': '5,500',
  '4995': '4,995',
  '2700': '2,700',
  '2300': '2,300',
  '3588': '3,588',


  // Terms
  "subscriptionAgreementTitle": "اتفاقية الإشتراك بالخدمة",
  "intro": "منصة متجرة لمواقع و تطبيقات المتاجر الإلكترونية هي منصة سعودية مسجلة بوزارة التجارة و الإستثمار السعودية ( مؤسسة المستشار لتقنية المعلومات ) تحت سجل التجاري رقم: 4030372176",
  "introNote": "ونبلغكم بأنكم سوف تجدون أدناه التزامات منصة متجرة تجاه مستخدميها و المشتركين معها بالإضافة لشروط إستخدام منصة متجرة وحقوق المستخدمين و هي عبارة عن الشروط الواجب على التاجر إتباعها وتعتبر هذه الشروط والأحكام بمثابة الإتفاقية كما تعتبر سارية المفعول بمجرد الموافقة عليها والبدء في باستخدام منصة متجرة.",
  "subscriptionTermsTitle": "شروط وأحكام الإشتراك بالخدمة",
  "subscriptionTerm1": "يجب على التاجر قراءة كافة الشروط الخاصة باستخدام المنصة و الموافقة عليها حتى يتمكن من استخدام المنصة والخدمات التي تقدمها.",
  "subscriptionTerm2": "إن الخدمة المقدمة من منصة متجرة بموجب شروط الاستخدام تتضمن العديد من المنتجات والخدمات التي تساعد التاجر ( المستخدم ) في إنشاء وإدارة النشاط التجاري الإلكتروني الخاص به.",
  "subscriptionTerm3": "يحق لمنصة متجره تحديث وتغيير شروط الاستخدام عن طريق نشر التحديثات والتغييرات على الموقع الإلكتروني الرسمي الخاص بها",
  "subscriptionTerm4": "يمنع تماماً استخدام أي خدمات مقدمة من قبل منصة متجرة لأي أغراض غير قانونية أو أغراض غير أخلاقية أو أي أمور تتعارض مع الشريعة الإسلامية",
  "registrationTitle": "أولاً: شروط التعامل والتسجيل بمنصة متجرة",
  "registrationTerm1": "يجب عليك التسجيل في منصة متجرة لاستخدام الخدمة والاستفادة منها عن طريق كتابة الاسم بشكل كامل كما هو موجود في الأوراق الرسمية والعنوان الحالي ورقم الجوال وبريد إلكتروني صحيح و يحق لمتجرة رفض طلب إنشاء الحساب أو إلغاء حساب ما لأي سبب بموجب الصلاحيات الممنوحة.",
  "registrationTerm2": "صاحب المتجر هو المسؤول عن حفظ كلمة المرور الخاصة بمتجره آمنة. كما أن متجرة غير مسؤولة عن أي خسارة أو ضرر يقع نتيجة للنسيان وعدم الحفاظ على أمن كلمة المرور من قبل صاحب المتجر.",
  "registrationTerm3": "يحق لمنصة متجرة استخدام عنوان البريد الإلكتروني ورقم الجوال كوسيلة للتواصل أو إرسال بعض العروض الهامة لك.",
  "registrationTerm4": "صاحب المتجر هو المسؤول عن جميع الأنشطة والمحتويات الخاصة بمحتوى متجره كالبيانات والرسومات والصور والروابط التي يتم تحميلها.",
  "registrationTerm5": "عند استخدام التاجر لوسائل الدفع المتاحة بالمنصة , فأنه يوافق على كافة شروط الشركات صاحبة هذه الوسائل كطرف ثالث موجود بمنصة متجرة كما هو مبين بشروط الاستخدام عند طلب خدمة إنشاء موقع أو تطبيق جديد أو متجر جديد , وإذا تم إجراء أي تعديلات أو تغييرات سيتم نشر ذلك في النسخة الجديدة في التطبيق أو الموقع أو المتجر.",
  "registrationTerm6": "من يقوم بفعل أي مخالفة أو قيامه بضرر متعمد لمنصة متجرة , فأنه بذلك سيعرض نفسه للحظر وإلغاء حسابه بشكل نهائي بالمنصة.",
  "registrationTerm7": "إذا كان لديك أية استفسارات أو أسئلة, يرجى التواصل معنا فوراً على الأرقام الموضحة بالمنصة (920033179 ) أو من خلال بريدنا الإلكتروني للإجابة على جميع استفساراتك وأسئلتك.",
  "generalProvisionsTitle": "ثانياً: الأحكام العامة",
  "generalProvision1": "تخضع شروط الاستخدام وتطبق وفقًا لقوانين المملكة العربية السعودية المعمول بها و تختص السلطة القضائية بالمملكة العربية السعودية بالنظر في النزاعات والقضايا التي من الممكن حدوثها لا قدر الله",
  "generalProvision2": "يتم توفير الدعم الفني لكل عملاء منصة متجرة ويكون متاح عن طريق الدردشة المباشرة في لوحة التحكم والبريد الإلكتروني.",
  "generalProvision3": "يقر صاحب المتجر بموافقته على أن منصة متجرة قد تعدل شروط الاستخدام هذه في أي وقت عن طريق نشر شروط الاستخدام المعدلة على الموقع الرسمي الخاص بمنصة متجرة والمتاحة في سياسة الخصوصية وتسري التعديلات على شروط الخدمة اعتبارًا من تاريخ النشر. فإن استخدام صاحب المتجر للخدمات بعد التعديل الذي يطرأ عليها والذي يتم نشره على الموقع الرسمي الخاص بمنصة متجرة يعتبر موافقة على شروط الاستخدام المعدلة وقبولها.",
  "generalProvision4": "لا يجوز لصاحب المتجر استخدام خدمة منصة متجرة لأي غرض غير قانوني أو غير مصرح به ولا يحق له استخدام الخدمة بما فيه انتهاك أي قوانين في المنطقة القضائية (بما في ذلك قوانين حق المؤلف) والقوانين المطبقة على المستخدم مع العميل الخاص بك أو قوانين المملكة العربية السعودية. سوف تلتزم بجميع القوانين والقواعد واللوائح المعمول بها في استخدامك للخدمة.",
  "generalProvision5": "يجب على صاحب المتجر أن يدرك أن محتوى المتجر الخاص به (باستثناء معلومات الدفع) يمكن نقلها غير مشفرة وتشمل الإرسال عبر الشبكات المختلفة. و التغييرات لتتكيف وتتماشى مع المتطلبات التقنية لشبكات أو أجهزة الربط. كما يتم تشفير معلومات الدفع دائمًا أثناء النقل من شبكة لأخرى.",
  "generalProvision6": "يقر صاحب المتجر ويوافق على أن استخدام المتجر بما في ذلك المعلومات التي يتم إرسالها إلى أو تخزينها بواسطة منصة متجرة ، تخضع لسياسة الخصوصية الخاصة بها.",
  "trialPeriodTitle": "فيما يخص الفترة التجريبية",
  "trialPeriodTerm1": "يمكن للتاجر استخدام جميع خصائص المتجر الالكتروني في الباقة الأساسية عدا تفعيل الربط مع بوابات الدفع الالكتروني و شركات الشحن والتوصيل حيث لايمكن تفعيل هذه الميزات إلا بعد الاشتراك في أحد الباقات المدفوعة.",
  "platformRightsTitle": "ثالثاً: حقوق منصة متجرة",
  "platformRights1": "لمنصة متجرة كامل الحق في حذف أي محتوى ينتهك حقوق الموقع أو ينتهك حقوق أطراف أخرى أو أن هذا المحتوى يحتوي على صور جنسية أو أغراض جنسية أو أية أضرار تسبب أي ضرر لأي شخص.",
  "platformRights2": "لمنصة متجرة كامل الحق في تعديل أو إنهاء أي خدمة من خدماتها دون إبلاغ التجار بذلك.",
  "platformRights3": "يحق لمنصة متجرة تقديم خدماتها لأي طرف حتى مع كونه منافساً لأطراف أخرى مستفيدة من ذات الخدمات، وتلتزم متجرة بالميثاق الأخلاقي والقانوني في عدم الإضرار بأي طرف أو الامتناع عن خدمته لتحقيق مكاسب لطرف آخر ولا يعدون بأي شكل من أشكال التفرد في أي قطاع من القطاعات المتاحة في السوق.",
  "platformRights4": "سوء المعاملة اللفظية أو الخطية من أي نوع (بما في ذلك التهديد بإساءة المعاملة أو الانتقام) من أي عميل لمتجرة ، أو موظف في متجرة، أو عضو أو مسؤول سيؤدي إلى إنهاء الحساب فورا.",
  "platformRights5": "في حال وجود نزاع بشأن ملكية الحساب، يحق لمتجرة طلب الوثائق للتحقق أو التأكد من ملكية الحساب.",
  "liabilityTitle": "رابعاً: حدود المسئولية",
  "liability1": "تخلي منصة متجرة مسؤوليتها في حالة وقوع أية ضرر لأي مستخدم بالمنصة على سبيل المثال حدوث ضرر للمستخدم نتيجة حدوث تعطل للمنصة أثناء عملية شراء الخدمات أو الإشتراك بالباقات المتاحة بالمنصة.",
  "liability2": "التاجر مسؤول بشكل كامل عن سلامة حسابه الموجود بمنصتنا , كما ننصح التاجر ألا يقوم بالكشف عن كلمة المرور الخاصة به لأي شخص حتى لا يتسبب بأي ضرر لحسابه بالمنصة.",
  "liability3": "منصة متجرة غير مسؤولة عن جودة الخدمات التي يقدمها التاجر لعملائه.",
  "liability4": "لا تضمن منصة متجرة أن تكون الخدمة التي تقدمها دون انقطاع، أو آمنة، أو خالية من الأخطاء.",
  "liability5": "لا تضمن منصة متجرة أن جودة أي منتجات أو خدمات أو معلومات أو مواد أخرى تم شراؤها أو الحصول عليها من صاحب المتجر عبر الخدمة سوف تصل لسقف توقعاتك أو أن أي أخطاء في الخدمة سيتم تصحيحها.",
  "liability6": "في جميع الأحوال تعتبر منصة متجرة غير مسؤولة عن فقدان الأرباح أو حدوث أي ضرر بأي شكل كان سواء بشكل مباشر أو غير مباشر لأي طرف من الأطراف الموجودة على المنصة.",
  "intellectualPropertyTitle": "خامساً: الملكية الفكرية والمحتوى الخاص بالعميل",
  "intellectualProperty1": "لا يحق لمنصة متجرة المطالبة بحقوق الملكية الفكرية على المحتوى التي ترفعه على منصة متجرة وتكون جميع المحتويات التي تقوم بتحميلها ملكاً لك ولا يفهم من ذلك الإعفاء من الالتزام بالشروط والسياسات الخاصة بمنصة متجرة أو عدم المساءلة عند الوقوع في المخالفة.",
  "intellectualProperty2": "عندما يتم رفع المحتوى للمتجر فإن المستخدم يوافق على مايلي: (أ) السماح لمستخدمي الإنترنت الآخرين بمشاهدة محتوى المتجر. (ب) السماح لمنصة متجرة بعرض محتوى المتجر الخاص بك وحفظه . (ج) يحق لمنصة متجرة ، مراجعة محتوى المتجر الذي تم رفعه في أي وقت للتأكد من سلامته والتزامه بالشروط والضوابط.",
  "intellectualProperty3": "يحتفظ المستخدم بملكية جميع المحتويات التي يتم رفعها للمتجر، وعلى أي حال عندما يكون المتجر عامًا فهو بمثابة موافقة للآخرين بمشاهدة محتوى المتجر. كما أن المستخدم مسؤول عن تطبيق الأنظمة و القوانين والاشتراطات السارية على محتوى المتجر.",
  "intellectualProperty4": "لن تكشف منصة متجرة معلومات المستخدم السرية للأطراف الثالثة إلا إذا كان ذلك مطلوبا أثناء تقديم الخدمة وبموافقة وعلم المستخدم مسبقاً.",
  "waiverAgreementTitle": "سادساً : التنازل والاتفاق الكامل",
  "waiverAgreement1": "إن عدم قيام منصة متجرة بممارسة أو تنفيذ أي حق أو شرط من شروط الإستخدام لا يعتبر تنازلًا عن هذا الحق.",
  "serviceFeesTitle": "سابعاً : دفع رسوم الخدمات",
  "serviceFee1": "يقوم التاجر بدفع كامل الرسوم المطلوبة منه نظير اشتراكه في الباقات المتاحة بمنصة متجرة.",
  "serviceFee2": "يجب على التاجر تزويد منصة متجرة ببيانات وسيلة الدفع الذي يريد الدفع من خلالها , وأن تكون هذه البيانات صحيحة بنسبة 100% , وأن تكون وسيلة الدفع مفعلة.",
  "serviceFee3": "يتم دفع رسوم الباقات مقدماً , ويمكنكم الاطلاع على الباقات المتاحة والموجودة في صفحة الباقات لإختيار الباقة المناسبة لكم .",
  "serviceFee4": "يجب أن يستخدم التاجر وسيلة دفع صالحة للإستخدام لكي يتمكن من الإشتراك في الباقات التي تقدمها منصة متجرة.",
  "serviceFee5": "لا يمكن استرجاع المبالغ المدفوعة لشراء الباقات الموجودة على منصة متجرة.",
  "priceChangesTitle": "ثامناً : التعديلات على الخدمات والأسعار",
  "priceChange1": "أسعار استخدام الخدمات غير ثابتة وقابلة للتغيير على أن يتم إشعار المستخدم قبل 30 يوما.",
  "priceChange2": "تحتفظ منصة متجرة بحق التعديل في أي وقت ومن وقت لآخر أو إيقاف الخدمة.",
  "priceChange3": "لا تكون متجرة مسؤولة تجاه المستخدم أو تجاه أي طرف ثالث عن أي تعديل أو تغيير في الأسعار أو تعليق أو إيقاف للخدمة.",
  "renewalProcessTitle": "تاسعاً :آلية تجديد الإشتراك",
  "renewalProcess1": "يمكن للتاجر تجديد الإشتراك الخاص بمتجره من خلال لوحة التحكم الخاصة بالمتجر.",
  "renewalProcess2": "يمكن للتاجر تجديد الاشتراك للباقة المختارة باستخدام طريقة الدفع اتي يختارها عند إنتهاء فترة اشتراكه الحالي.",
  "renewalProcess3": "يمكن تجديد الإشتراك عن طريق الدفع الإلكتروني بالبطاقات وعندها يتم تفعيل الإشتراك بشكل تلقائي.",
  "cancellationTitle": "عاشراً  : إلغاء الإشتراك",
  "cancellation1": "يمكنك إلغاء اشتراكك أو إيقاف الخدمة المقدمة لك من منصة متجرة في أي وقت.",
  "nonRenewalPolicyTitle": "الحادي عشر : إجراءات وحالة المتجر في حال عدم التجديد وفترة السماح",
  "nonRenewalPolicy1": "في حال إنتهاء فترة الإشتراك في الباقة فو عدم دفع رسوم التجديد الخاص بالباقة، يتوقف المتجر عن العمل.",
  "nonRenewalPolicy2": "يعطى المتجر فترة توقف مؤقت وتستمر فترة التوقف المؤقت لمدة (30 يوم)، وفي حالة عدم التجديد قبل انتهاء فترة التوقف ، سوف يتم حذف المتجر بشكل نهائي.",
  "refundPolicyTitle": "الثاني عشر : سياسة الإسترجاع",
  "refundPolicy1": "تعتمد الرسوم في منصة متجرة على الخدمة التي يتم شراؤها بغض النظر عن الاستخدام الفعلي للخدمة وبشكل غير قابل للاسترداد.",
  "thirdPartyServicesTitle": "الثالث عشر : خدمات شركات الطرف الثالث",
  "thirdPartyService1": "يوافق صاحب المتجر بالإضافة لهذه الشروط على الالتزام بشروط الاستخدام الإضافية التي تنطبق على الخدمات التي تشتريها أو التي يقدمها شركاء منصة متجرة أو أطراف ثالثة أخرى.",
  "thirdPartyService2": "يجوز لمنصة متجرة أن تمنح صاحب المتجر من وقت لآخر برامج أو منتجات أو خدمات طرف ثالث.",
  "thirdPartyService3": "أي استخدام لخدمات الطرف الثالث المقدمة هو مسؤولية صاحب المتجر الخاصة، كما أنه مسؤول عن قراءة الشروط والأحكام وسياسات الخصوصية المطبقة على خدمات الطرف الثالث قبل استخدامها.",
  "thirdPartyService4": "لا تقدم منصة متجرة أي ضمانات فيما يتعلق بخدمات الطرف الثالث.",
  "thirdPartyService5": "إذا قام صاحب المتجر بتثبيت خدمة طرف ثالث أو تمكينها للاستخدام مع الخدمات، فإنه يمنح منصة متجرة الإذن للسماح للطرف الثالث الساري بالوصول إلى البيانات الخاصة به.",
  "thirdPartyService6": "لا تتحمل منصة متجرة تحت أي ظرف من الظروف مسؤولية أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة.",

  // FAQ
  "faqTitle": "الأسئلة الشائعة",
  "faqDescription": "ماهي الخدمات المشمولة في الباقات؟ تشمل الباقات جميع الميزات والخدمات اللازمة لإطلاق متجرك الإلكتروني بداية من مرحلة الإعداد وحتى الربط مع مزودي الخدمات بأسعار حصرية وتفعيل الدفع الالكتروني بدون أي رسوم تأسيسية أو شهرية وحتى التسويق عن طريق نظام التسويق بالعمولة ونقاط المكافئات و قسائم التخفيض والعديد من الأدوات التسويقية الأخرى.",
  "faq1Title": "ماهي الخدمات المشمولة في الباقات؟",
  "faq1Content": "تشمل الباقات جميع الميزات والخدمات اللازمة لإطلاق متجرك الإلكتروني بداية من مرحلة الإعداد وحتى الربط مع مزودي الخدمات بأسعار حصرية وتفعيل الدفع الالكتروني بدون أي رسوم تأسيسية أو شهرية وحتى التسويق عن طريق نظام التسويق بالعمولة ونقاط المكافئات و قسائم التخفيض والعديد من الأدوات التسويقية الأخرى.",
  "faq2Title": "هل أحتاج إلى خبرة تقنية لإعداد متجري؟",
  "faq2Content": "لا تحتاج لأي خبرة تقنية، في متجرة سهلنا عليك كل شيء وكمان وفرنا لك فريق دعم فني متميز يساعدك في إعداد وتجهيز متجرك ومساعدتك دائما",
  "faq3Title": "هل يوجد فترة تجريبية ؟",
  "faq3Content": "نعم، الفترة التجريبية مدتها 14 يوم، وتمكنك الاستفادة من كل مميزات الباقة الأساسية مجانًا .لمدة 14 يوم.",
  "faq4Title": "بماذا تتميز متجرة عن المنصات الأخرى ؟",
  "faq4Content": "تتميز منصتنا بتجربة أكثر سهولة في بناء المتجر كما نوفر لكم تواصل فعال من خلال فريقي المساندة والانطلاقة",
  "faq5Title": "هل يمكنني نقل متجري الحالي لمتجرة ؟",
  "faq5Content": "نعم يمكنك ذلك ، فريق الانطلاقة سيساعدك في ذلك",
  "faq6Title": "كم تستغرق عملية إعداد المتجر ؟",
  "faq6Content": "تستغرق عملية إنشاء المتجر ثوانٍ معدودة ويمكنك البدء مباشرة باستقبال طلباتك باستخدام التحويل البنكي والدفع عند الاستلام ، وتحتاج فقط إلى ٤٨ ساعة إضافية لتفعيل الدفع الالكتروني",
  "faq7Title": "ماهي مراحل بناء تطبيق جوال لمتجري؟ هل التطبيق نيتف ؟",
  "faq7Content": "نعم تطبيق الجوال نيتف وليس ويب فيو وبخيارات تصميمات احترافية ومتعددة ويتم بناء التطبيق على ٣ مراحل : المرحلة الأولى : المرحلة الثانية : المرحلة الثالثة",
  "faq8Title": "كم تستغرق عملية بناء تطبيق جوال لمتجري ؟ ",
  "faq8Content": "تستغرق عملية تعبئة بيانات طلب تطبيق المتجر دقائق معدودة نقوم بعدها برفع التطبيقات خلال ٧٢ ساعة ، بعدها تقوم المنصات بمراجعة التطبيقات android و ios إلى منصات والموافقة على نشرها في مدة تتراوح من يوم إلى ٧ أيام",
  "faq9Title": "كيف اتواصل مع الدعم الفني؟",
  "faq9Content": "يمكنكم التواصل مع الدعم الفني عن طريق المحادثة الفوري في أي وقت من العاشرة صبا ًحا وحتى الثامنة مساءً ، يسعى دائما فريق الدعم الفني الخاص بنا على التجاوب معك . وحل مشاكلك للنهاية.",
  "faq10Title": "هل الاشتراك يشمل تحديثات لمتجري؟",
  "faq10Content": "نعم الاشتراك يشمل تحديثات مستمرة بإضافة مميزات جديدة والربط مع مزودي خدمات",
  "faq11Title": "هل المتجر يدعم عملات ولغات مختلفة ؟",
  "faq11Content": ".نعم، يدعم عملات دول الخليج ويدعم العربية والانجليزية",
  "faq12Title": "ما هي الدول اللتي تدعمها متجرة ؟",
  "faq12Content": "تعمل متجرة في جميع دول الخليج",
  "faq13Title": "هل أستطيع إلغاء الخدمة والحصول على بيانات متجري؟",
  "faq13Content": "نعم تسطيع ذلك في أي وقت",

  pricingTitle: "تكلفة إنشاء متجر إلكتروني - باقات الأسعار",
  pricingDescription: "باقات أسعار مختلفة وتكلفة إنشاء متجر إلكتروني تناسب الجميع مع توفير جميع الخدمات الأساسية اللازمة لتصميم متجرك الالكتروني والأدوات التي تساعد متجرك في النمو",
  marketing: "التسويق",
  discountCoupons: "كوبونات الخصم و التخفيض",
  automaticDiscountSystem: "نظام الخصومات التلقائية",
  smsCampaigns: "الحملات التسويقية لعملاء المتجر عبر الـSMS",
  orderUpdatesWhatsApp: "رسائل تحديثات حالات الطلبات والسلات المتروكة من خلال الواتس آب",
  abandonedCartSystem: "نظام السلّات المتروكة",
  loyaltyPointsSystem: "نظام نقاط الولاء والمكافآت",
  includedInPackageNoAdditionalFee: "متضمنة بالباقة دون رسوم إضافية",
  topBannerAds: "إعلانات الشريط العلوي",
  emailMarketing: "التسويق بالبريد الإلكتروني",
  affiliateMarketingSystem: "نظام التسويق بالعمولة",
  marketingLinksSystem: "نظام الروابط التسويقية",
  backInStockNotification: "إشعار عودة المخزون",
  seoOptimization: "تحسين الظهور في محركات البحث SEO",
  integrationServices: "خدمات الربط",
  analyticsIntegration: "إمكانية الربط مع خدمات التحليل",
  liveChatIntegration: "إمكانية الربط مع خدمات المحادثة الفورية",
  messageServiceIntegration: "إمكانية الربط مع خدمات الرسائل",
  emailServiceIntegration: "إمكانية الربط مع خدمات البريد الإلكتروني",
  adServiceIntegration: "إمكانية الربط مع الخدمات الاعلانية Ads Pixel, Snapchat, Twitter, Facebook, Google Ads, Google Tag Manager, Pintrest, AdRoll",
  zapierIntegration: "الربط مع Zapier",
  comingSoon: "قريباً",
  linkArabyIntegration: "إمكانية الربط مع منصة لينك عربي للتسويق",
  accountingIntegration: "إمكانية الربط مع الخدمات المحاسبية Zoho Books - قيود - دفترة",
  storeGrowthTracking: "متابعة نمو المتجر",
  storeGrowthEmployee: "موظف متابعة نمو المتجر",
  regularMeetings: "اجتماعات دورية للمتابعة",
  oneOnlineMeetingAtStart: "اجتماع أونلاين واحد بداية الإشتراك",
  twoOnlineMeetingsMonthly: "إجراء إجتماعات دورية حتى 2 اجتماع أونلاين شهرياً",
  marketingToolsSupport: "شرح عن الأدوات التسويقية بلوحة التحكم  والمساعدة بربطها",
  storeManagementTips: "إعطاء نصائح لإدارة المتجر بطرق صحيحة",
  basicServices: "خدمات أساسية",
  unlimitedProducts: "عدد منتجات لا محدود",
  unlimitedCustomers: "عدد عملاء لا محدود",
  unlimitedOrders: "عدد طلبات لا محدود",
  unlimitedSections: "أقسام غير محدودة للمتجر",
  brandsAndTags: "الماركات والعلامات التجارية",
  storeDomainLink: "ربط المتجر بنطاق خاص",
  freeDomain: "نطاق مجاني للمتجر",
  annualOnly: "السنوية فقط",
  sslProtection: "حماية المتجر بواسطة شهادة SSL",
  productReviews: "التقييمات والمراجعات للمنتجات",
  productQandA: "الأسئلة والإجابات للمنتجات",
  multiOptionProducts: "دعم الخيارات المتعددة للمنتجات",
  productExportImport: "تصدير و استيراد المنتجات",
  excelProductUpdate: "تحديث المنتجات بواسطة اكسل شيت",
  supportForVAT: "دعم ضريبة القيمة المضافة",
  multiCurrencySupport: "دعم تعدد العملات في المتجر",
  arabicEnglishSupport: "دعم اللغات عربي / إنجليزي",
  reportSystem: "نظام تقارير وإحصائيات عن المتجر",
  basicReports: "تقارير أساسية",
  comprehensiveReports: "تقارير شاملة",
  extraPages: "صفحات إضافية تعريفية بالمتجر",
  customerTestimonials: "آراء العملاء بالمتجر",
  customizableThemes: "ثيمات متنوعة لتصميم المتجر",
  salesCommission: "عمولة على المبيعات",
  panelTeam: "فريق عمل باللوحة",
  unlimited: "غير محدود",
  storeManagementApp: "تطبيق إدارة المتجر",

};
